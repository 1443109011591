<script lang="ts" setup>
import { useVModel } from '@vueuse/core';

defineOptions({ name: 'BaseSwitch' });

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    label?: string;
  }>(),
  {
    size: 'medium',
    label: undefined,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const value = useVModel(props, 'modelValue', emit);
</script>

<template>
  <label
    class="relative box-border inline-block cursor-pointer select-none"
    :class="{ 'cursor-not-allowed': disabled }"
    data-testid="base-switch"
  >
    <input
      v-model="value"
      type="checkbox"
      class="peer sr-only"
      data-testid="bs-checkbox"
      :disabled="disabled"
    />
    <div
      class="switch p-1x outline-2 outline-offset-2 transition-all duration-75 peer-focus-visible:outline"
      :class="[modelValue ? 'bg-mint-500' : 'bg-navy-300', size]"
    >
      <div class="h-[1em] w-[1em] rounded-full bg-white transition-transform duration-75" />
    </div>
    <span
      v-if="label"
      :class="{ 'text-disabled': disabled }"
      class="ml-1.5x align-middle font-bold transition-colors duration-75"
    >
      {{ label }}
    </span>
  </label>
</template>

<style scoped>
.switch {
  display: inline-block;
  width: 1.875em;
  height: 1em;
  vertical-align: middle;
  border-radius: theme('borderRadius.full');

  &.small {
    font-size: theme('fontSize.sm');
  }

  &.medium {
    font-size: theme('fontSize.base');
  }

  &.large {
    font-size: theme('fontSize.lg');
  }
}

input[type='checkbox']:disabled + .switch {
  opacity: 0.5;
}

input[type='checkbox']:checked + .switch > * {
  transform: translateX(calc(0.875em));
}
</style>
