export default {
  en: {
    banner: {
      altText: {
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
        success: 'Success',
      },
    },
    files: {
      loader: {
        text: 'Drag & drop your files here or',
        button: 'Select File',
        error: 'Failed to load file',
      },
      downloadError: 'Error downloading attachment',
      filesAttached: '{n} file attached | {n} files attached',
    },
    debug: {
      userRole: 'Change your user role',
      accountType: 'Change your account type',
      comingSoon: {
        title: 'Coming soon',
        subtitle: 'Nothing to see here just yet...',
      },
      failingRequest: 'Make a failing request (500 for god user, 403 for rest)',
      godWillAlert: 'God Users will alert!',
      getError: 'Get Error',
      goToZeus: 'Zeus',
      goToNetworkGraph: 'Network Graph',
      goToSalesFederatedGraph: 'Federated Graph (Fake Data)',
      orgID: 'Org ID:',
      userID: 'User ID:',
      changeRole: 'Change Role:',
      setProfileAsIncomplete: 'Set Profile As Incomplete',
    },
    nav: {
      gettingStarted: 'Getting Started',
      risks: 'Risks',
      myAssurance: 'My Assurance',
      mySecurity: 'My Security',
      dashboard: 'Dashboard',
      insights: 'Insights',
      beta: 'Beta',
      new: 'New',
      reporting: 'Reporting',
      communities: 'Community',
      visualisation: 'Visualisation',
      network: 'Network',
      map: 'Map',
      actionCentre: 'Action Centre',
      discussions: 'Discussions',
      suppliers: 'Suppliers',
      policies: 'Policies',
      clients: 'Clients',
      assessment: 'Assessment',
      monitoring: 'Monitoring',
      emergingThreats: 'Emerging Threats',
      settings: 'Settings',
      sharing: 'Sharing',
      whatIsRL: 'What is Risk Ledger?',
      feedback: 'Feedback?',
      feedbackMessage: 'I would like to give some feedback 👋',
      debugMenu: 'Debug Menu',
      popover: {
        contactUs: 'Contact Us',
        contactUsMessage: 'I would like some help 👋',
        knowledgeBase: 'Knowledge Base',
        helpCentre: 'Help Centre',
        signOut: 'Sign Out',
      },
      addUsers: 'Add Users',
      inviteUsers: 'Invite Users',
      version: {
        title: 'Risk Ledger v.{version}',
        updated: 'Updated {date}',
      },
      subEntities: 'Sub-Entities',
      documents: 'Documents',
      answerSearch: 'Answer Search',
    },
    tab: {
      scrollLeft: 'Scroll left',
      scrollRight: 'Scroll right',
    },
    infiniteLoader: {
      loadMore: 'Load more',
    },
    confirmModal: {
      title: 'Are you sure?',
      closeButtonText: 'Cancel',
      actionButtonText: 'Confirm',
      checkboxText: 'I understand the consequences of this action.',
    },
    ff: {
      featureFlags: 'Feature Flags',
      localStorage: 'The default value has changed, it has been persisted to local storage.',
      reset: 'Reset Flags to Default',
    },
    orderedLabelEditor: {
      addNew: 'Add Level',
      addNewPlaceholder: 'Enter label name...',
      addNewSave: 'Save',
    },
    public: {
      privacyPolicy: 'Privacy Policy',
      termsAndConditions: 'Terms & Conditions',
      copyright: '© Copyright Risk Ledger Ltd {year}',
      rlAddress: 'Adam House, 7-10 Adam St, London, WC2N 6AA, United Kingdom',
    },
    richTextArea: {
      toggleBold: 'Toggle bold',
      toggleItalic: 'Toggle italic',
      toggleUnderline: 'Toggle underline',
      toggleList: 'Toggle list',
      toggleLink: 'Toggle link',
      attachFiles: 'Attach files',
      linkPopover: {
        setTitle: 'Set Link',
        updateTitle: 'Update Link',
        remove: 'Remove',
        save: 'Save',
      },
    },
    dataTable: {
      empty: 'No rows to display',
      error: 'Failed to get rows',
      status: {
        justNow: 'just now',
      },
      searchPlaceholder: 'Search...',
      columns: 'Columns',
    },
    video: {
      loomCloseButton: 'Close',
    },
    layouts: {
      applicationLoadError: {
        message: 'The application has failed to load.',
        stepsToFollowHeader: 'To resolve this please try the following steps:',
        tryRefreshing: 'Try refreshing your browser',
        openSiteInPrivacyMode: 'Try opening this site in incognito/privacy mode',
        ensureWhitelistedDomains: 'Ensure riskledger domains are whitelisted by your IT team',
        contactUsThroughIntercom:
          'If none of the above work please contact our support staff by clicking here.',
        intercomMessage: "I'm experiencing an issue with the application loading.",
      },
    },
    cancelWarningModal: {
      title: 'Are you sure you want to leave?',
      body: 'Any changes you’ve made will be lost.',
      no: 'Keep editing',
      yes: 'Yes, leave',
    },
    copyLink: {
      copyTooltip: 'Copy the link to your clipboard.',
    },
  },
};
