import type { RouteRecordRaw } from 'vue-router';
import { requireFlag } from '@/routes/guards/flag';

export const supplierMonitoringRoutes = [
  {
    path: 'monitoring',
    name: 'supplier-monitoring',
    beforeEnter: [requireFlag('continuousMonitoringInterest')],
    component: () => import('./SupplierMonitoring.vue'),
  },
] satisfies RouteRecordRaw[];
