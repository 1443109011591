export default {
  en: {
    dashboardBanner: {
      title: 'External Monitoring',
      comingSoon: 'Coming soon',
      text: "Gain real-time insights on your suppliers' security posture, empowering collaboration and informed decision-making across your entire supply chain",
      cta: 'Register your interest',
      thanks: 'Thanks for your interest',
      toggleAnimation: 'Toggle animation',
    },
    supplierMonitoring: {
      pageTitle: 'External Monitoring',
      title: 'External Monitoring',
      text: 'Gain real-time, actionable insights, allowing you to visualise your external security posture',
      cta: 'Register your interest',
      thanks: 'Thanks for your interest',
    },
  },
};
